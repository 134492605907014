import React from "react";
import "./contact.css";
import { Link } from "react-scroll";
import logo from "../../img/logo_contact.png";
import { FaWhatsapp, FaInstagram, FaFacebookF, FaTiktok } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaSnapchat } from "react-icons/fa6";
import { CiTwitter } from "react-icons/ci";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div id="contact">
      <div className="contact">
        <div className="all_contact">
          <div className="right">
            <ul className="links">
              <li>
                <Link
                  to="header"
                  spy={true}
                  smooth={true}
                  offset={-200}
                  duration={500}
                >
                  {t("home")}
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  {t("about_us")}
                </Link>
              </li>
              <li>
                <Link
                  to="exhibtions"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  {t("booths_types")}
                </Link>
              </li>
              <li>
                <Link
                  to="product"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  {t("our_products")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="center">
            <h2>
              {t("contact_us")} <span></span>
            </h2>

            <a
              className="whatsapp"
              href="https://api.whatsapp.com/send?phone=97430059780"
              target="_blank"
            >
              <FaWhatsapp />
              +974 3005 9780
            </a>

            <a
              className="mailto"
              href="mailto:info@mdevents.qa"
              target="_blank"
            >
              <MdOutlineEmail /> info@mdevents.qa
            </a>

            <div>
              <a
                href="https://web.facebook.com/people/Md-Events/61554008902349/"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com/mdevents.qa/" target="_blank">
                <FaInstagram />
              </a>
              <a href="https://www.tiktok.com/@mdeventsqa" target="_blank">
                <FaTiktok />
              </a>
              <a href="https://twitter.com/MdEventsqa" target="_blank">
                <CiTwitter />
              </a>
              <a href="https://www.snapchat.com/add/mdeventsqa" target="_blank">
                <FaSnapchat />
              </a>
            </div>
          </div>
          <div className="left">
            <Link
              to="header"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
              className="logo"
            >
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

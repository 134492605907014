import React from "react";
import "./exhibtions.css";
import exhibtionsafter from "../../img/exhibtionsafter.png";
import { useTranslation } from "react-i18next";

const Exhibtions = () => {
  const { t } = useTranslation();
  const exhibtion = [
    {
      name: "VVIP",
      price: "20,000.00",
      qr: "QR",
    },
    {
      name: "VIP",
      price: "18,000.00",
      qr: "QR",
    },
    {
      name: "PLATINUM",
      price: "15,000.00",
      qr: "QR",
    },
    {
      name: "GOLD",
      price: "12,000.00",
      qr: "QR",
    },
    {
      name: "SILVER",
      price: "10,000.00",
      qr: "QR",
    },
  ];

  return (
    <div id="exhibtions">
      <div className="exhibtions">
        <div className="after">
          <img src={exhibtionsafter} alt="exhibtionsafter" />
        </div>
        <h2>
          {t("types_booths_prices")} <span></span>
        </h2>

        <div className="exhibtion">
          {exhibtion.map((exhibtion, index) => (
            <div key={index} className={`item${index + 1}`}>
              <p>{exhibtion.name}</p>
              <p>{exhibtion.price}</p>
              <p>{exhibtion.qr}</p>
            </div>
          ))}
        </div>
        <div className="before">
          <img src={exhibtionsafter} alt="exhibtionsafter" />
        </div>
      </div>
    </div>
  );
};

export default Exhibtions;

import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Header from "../header/Header";
import About from "../About/About";
import Exhibtions from "../exhibtions/Exhibtions";
import Product from "../product/Product";
import Contact from "../contact/Contact";
import Copyrights from "../../components/copyrights/Copyrights";

const Home = ({ setDirection, direction }) => {
  return (
    <>
      <Navbar setDirection={setDirection} direction={direction} />
      <Header />
      <About />
      <Exhibtions />
      <Product />
      <Contact />
      <Copyrights />
    </>
  );
};

export default Home;

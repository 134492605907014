import React from "react";
import "./product.css";
import product_1 from "../../img/product_1.png";
import product_2 from "../../img/product_2.png";
import product_3 from "../../img/product_3.png";
import product_4 from "../../img/product_4.png";
import product_5 from "../../img/product_5.png";
import product_6 from "../../img/product_6.png";
import { useTranslation } from "react-i18next";

const Product = () => {
  const { t } = useTranslation();
  const product_all = [
    {
      productSrc: product_1,
      text: "PERFUMES",
    },
    {
      productSrc: product_2,
      text: "JEWELRY",
    },
    {
      productSrc: product_3,
      text: "BAGES & SHOSE",
    },
    {
      productSrc: product_4,
      text: "WOMEN'S ABAYAS",
    },
    {
      productSrc: product_5,
      text: "CHILDREN CLOTHES",
    },
    {
      productSrc: product_6,
      text: "MEN CLOTHES",
    },
  ];

  return (
    <div id="product">
      <div className="product">
        <h2>
          {t("our_products")} <span></span>
        </h2>
        <div className="product_all">
          {product_all.map((product, index) => (
            <div key={index} className={`product_${index + 1}`}>
              <img src={product.productSrc} alt={product.text} />
              <h3>{product.text}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;

export const arabicDataAr = {
  home: "الرئيسية",
  about_us: "عن المعرض",
  booths_types: "أنواع البوثات",
  our_products: "منتجاتنا",
  contact_us: "اتصل بنا",
  About_exhibition: "عن المعرض",
  About_exhibition_1:
    "نُرحب بكم في أجواء تجسد ملتقى الاأناقة، مصمم لكشف فخامة أسلوب الحياة وإبداعها في التفاصيـل بما تحتويه من أزياء ومجوهرات وصحة وجمــال وغيرها، في تجربة لم يسبق لها مثيل، لتكون تجربـة استثنائية هنالك أكثرمما تراه العيـن.",
  About_exhibition_2: "إنضم إلينا في قلب قطر حيث الرقي والإبداع.",
  About_exhibition_3: "ندعوك لتجربة استثنائية..",
  Address: "العنوان",
  Address_1: "فندق بولمان, الخليج الغربي الدوحة,قطر",
  Address_2: "من 1-2-2024",
  Address_3: "وحتى 4-2-2024",
  types_booths_prices: "أنواع البوثات وأسعارها",
  name_company: "معرض مَـدّ التجاري",
};

export default arabicDataAr;

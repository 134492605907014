import React, { useState } from "react";
import "./navbar.css";
import logo from "../../img/logo.png";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Navbar = ({ setDirection, direction }) => {
  const [isChecked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  const { i18n, t } = useTranslation();

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };

  return (
    <>
      <nav>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />

        <Link
          to="header"
          spy={true}
          smooth={true}
          offset={-200}
          duration={500}
          className="logo"
        >
          <img src={logo} alt="logo" />
        </Link>

        <ul className="links" onClick={handleToggle}>
          <li>
            <Link
              onClick={handleToggle}
              to="header"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("home")}
            </Link>
          </li>
          <li>
            <Link
              onClick={handleToggle}
              to="about"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              {t("about_us")}
            </Link>
          </li>
          <li>
            <Link
              onClick={handleToggle}
              to="exhibtions"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              {t("booths_types")}
            </Link>
          </li>
          <li>
            <Link
              onClick={handleToggle}
              to="product"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              {t("our_products")}
            </Link>
          </li>
          <li>
            <Link
              onClick={handleToggle}
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              {t("contact_us")}
            </Link>
          </li>

          {direction == "ltr" ? (
            <li>
              <button className="ChangeLang" onClick={ChangeAR}>
                AR
              </button>
            </li>
          ) : (
            <li>
              <button className="ChangeLang" onClick={ChangeEN}>
                EN
              </button>
            </li>
          )}
        </ul>

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>
    </>
  );
};

export default Navbar;

import React from "react";
import "./about.css";
import about from "../../img/about.png";
import address_1 from "../../img/address_1.png";
import address_2 from "../../img/address_2.png";
import address_3 from "../../img/address_3.png";
import { SiGooglemaps } from "react-icons/si";
import { MdDateRange } from "react-icons/md";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <div className="about_us">
        <img src={about} alt="about" />

        <div className="text">
          <h1>
            {t("About_exhibition")} <span></span>
          </h1>
          <p>{t("About_exhibition_1")} </p>
          <p>{t("About_exhibition_2")}</p>
          <p>{t("About_exhibition_3")}</p>
        </div>
      </div>
      <div className="full_address">
        <img className="address_2" src={address_2} alt="address_2" />
        <div className="address">
          <img src={address_1} alt="address_1" />
          <div className="text_address">
            <h2>
              {t("Address")}
              <span></span>
            </h2>

            <div>
              <SiGooglemaps />
              <p>
                {t("name_company")}
                <br />
                {t("Address_1")}
              </p>
            </div>

            <div>
              <MdDateRange />
              <p>
                {t("Address_2")}
                <br />
                {t("Address_3")}
              </p>
            </div>
          </div>
        </div>
        <img className="address_3" src={address_3} alt="address_3" />
      </div>
    </div>
  );
};

export default About;

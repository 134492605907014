import React, { useState, useEffect } from "react";
import "./header.css";
import element_5 from "../../img/element_5.png";
import slide_1 from "../../img/slide_1.png";
import slide_2 from "../../img/slide_2.png";
import slide_3 from "../../img/slide_3.png";
import { useAppContext } from "../../components/context/AppProvider";

const Header = () => {
  const { direction } = useAppContext();

  const texts_ar = [
    {
      arabic: ["مزيج من الثقافة", "القطرية والعالمية"],
      english: ["A blend of Qatari and", "international culture."],
      img: [slide_1],
    },
    {
      arabic: ["معرض", "مَدّ التجاري"],
      english: ["خياركم الأمثل", "Your Best Choice"],
      img: [slide_2],
    },
    {
      arabic: ["خدمات متكاملة", "لصحتك وجمالك"],
      english: ["Comprehensive Services", "For your health and beauty"],
      img: [slide_3],
    },
  ];

  const texts_en = [
    {
      arabic: ["A blend of Qatari and", ""],
      english: ["international culture", ""],
      img: [slide_1],
    },
    {
      arabic: ["Md", "Exhibition Trading"],
      english: ["Your Best Choice", ""],
      img: [slide_2],
    },
    {
      arabic: ["Comprehensive services", "For"],
      english: ["your health and beauty", ""],
      img: [slide_3],
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts_ar.length);
        setIsVisible(true);
      }, 1000);
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const currentText_ar = texts_ar[currentIndex];
  const currentText_en = texts_en[currentIndex];

  return (
    <div id="header">
      {direction == "ltr" ? (
        <div className="header_content">
          <img src={element_5} alt="element_5" className="element_5" />

          <div className="left_right">
            <div className="left">
              <div className={`top ${isVisible ? "visible" : "hidden"}`}>
                <h1>{currentText_en.arabic[0]}</h1>
                <h1>{currentText_en.arabic[1]}</h1>
              </div>

              <div className={`bottom ${isVisible ? "visible" : "hidden"}`}>
                <p>{currentText_en.english[0]}</p>
                <p>{currentText_en.english[1]}</p>
              </div>
            </div>
            <div className={`right ${isVisible ? "visible" : "hidden"}`}>
              <img src={currentText_en.img[0]} alt="GIRL" />
            </div>
          </div>
        </div>
      ) : (
        <div className="header_content">
          <img src={element_5} alt="element_5" className="element_5" />

          <div className="left_right">
            <div className="left">
              <div className={`top ${isVisible ? "visible" : "hidden"}`}>
                <h1>{currentText_ar.arabic[0]}</h1>
                <h1>{currentText_ar.arabic[1]}</h1>
              </div>

              <div className={`bottom ${isVisible ? "visible" : "hidden"}`}>
                <p>{currentText_ar.english[0]}</p>
                <p>{currentText_ar.english[1]}</p>
              </div>
            </div>
            <div className={`right ${isVisible ? "visible" : "hidden"}`}>
              <img src={currentText_ar.img[0]} alt="GIRL" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

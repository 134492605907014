import React from "react";
import NotFound from "./page/notFound/NotFound";
import { Route, Routes } from "react-router-dom";
import Home from "./page/home/Home";
import { useAppContext } from "./components/context/AppProvider";

const App = () => {
  const { direction, setDirection } = useAppContext();

  return (
    <div style={{ direction: direction }}>
      <Routes>
        <Route
          path="/"
          element={<Home setDirection={setDirection} direction={direction} />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;

export const englishDataEn = {
  home: "Home",
  about_us: "About gallery",
  booths_types: "Booths types",
  our_products: "Our products",
  contact_us: "Contact Us",
  About_exhibition: "About Exhibition",
  About_exhibition_1:
    "Welcome to an atmosphere of elegance, designed to showcase the luxury of lifestyle in different aspects like  fashion, jewellery, health and beauty.",
  About_exhibition_2:
    "Join us at the heart of Qatar, where elegance and innovation.",
  About_exhibition_3: "We invite you to an exceptional experience.",
  Address: "Address",
  Address_1: "Pullman Hotel, West Bay - Doha",
  Address_2: "From 1 - 2 - 2024",
  Address_3: "To 4 - 2 -2024",
  types_booths_prices: "Types of booths and prices",
  name_company: "Md Exhibition Trading",
};

export default englishDataEn;
